// -- -- -- -- -- -- -- -- -- -- --
// Init the Slide Function

$( document ).ready(function() { 
    //console.log('ready'); 
    
    jQuery(function($) {
      function equalHeight(group) {
        //group.css('height','auto'); 
        var tallest = 0;
        group.each(function() {
           var thisHeight = $(this).height();
           if(thisHeight > tallest) {
            tallest = thisHeight;
           }
        });
        group.height(tallest);
      }
      
      if ($(window).width() > 767) {
         equalHeight($(".equalheight"))
      }
      
      function equalMinHeight(group) {
        //group.css('height','auto'); 
        var tallest = 0;
        group.each(function() {
           var thisHeight = $(this).height();
           console.log(thisHeight);
           if(thisHeight > tallest) {
            tallest = thisHeight;
           }
        });
        group.css('min-height' , ''+tallest+'px');
      }
      if ($(window).width() > 767) {
         equalMinHeight($(".equalminheight"))
      }
    
    });
    
    
    
    
    $('main .container ul').addClass('mt-4');
    $('main .container ul > li').addClass('mb-3');
    $('.single-post main .container ul > li').addClass('mb-2').removeClass('mb-3');
    $('body.single-post main article .bluegain_block--bluegain_wysiwyg ul li').css({'font-size' : '17px','line-height' : '30px'});
    $('body.single-post main article .bluegain_block--bluegain_wysiwyg').css({'padding-bottom':'50px','padding-top':'50px'});
    $('body.single-post main article .bluegain_block--bluegain_wysiwyg img').removeAttr('height width'); 
    $('body.single-post main article .bluegain_block--bluegain_insightchart .text-center img').css({'max-width':'50%'});
    $('.single-post #pdf-gen-loader').hide();
    $( ".btn-pdf-download" ).one( "click", function(e) {
        $('body.single-post main article .bluegain_block--bluegain_pageheader .header-img img').css({'height':'300px','filter':'grayscale(1)'});
        $('.single-post #pdf-gen-loader').show();
        $('.single-post .btn-pdf-download img').hide();
//$('body.single-post main article .bluegain_block--bluegain_insightchart .text-center img').css({'max-width':'50%'});
        var id = $('body.single-post main article').attr('id');
        var date = $('body.single-post main article #date-wrapper'); 
        var name = $('body.single-post main article .bluegain_block--bluegain_pageheader .container.is-single .main-row h2').text(); 
        $('body.single-post main article .bluegain_block--bluegain_teasertext h5').css({'font-size' : '16px','line-height' : '25px','font-weight' : '500','font-style' : 'italic'});
        var sc = $('body.single-post main article .bluegain_block--bluegain_pageheader .sfsi_widget');
        sc.remove();
        var cate = $('body.single-post main article .bluegain_block--bluegain_pageheader .slider-post-category-wrapper');
        cate.remove();
        var ml = $('body.single-post main article .bluegain_block--bluegain_pageheader .main-row.d-block.d-md-none');
        ml.remove();
        $('body.single-post .bluegain_block--bluegain_teasertext .excerpt-wrapper .btn-bluegain').addClass("d-none");
        var ae = $('body.single-post main article .bluegain_block--bluegain_pageheader .header-img .after-elmnt-img');
        ae.remove();
        
        var content = "";
        $('body.single-post main article > .bluegain_block').each(function( index ) {
            if(!$(this).hasClass('bluegain_block--bluegain_download') && !$(this).hasClass('bluegain_block--bluegain_post-slider') && !$(this).hasClass('bluegain_block--bluegain_newsletter')){
                content += $(this).html();
            }
        });
        name = name.slice(2);
        id = id.slice(5);
        console.log(id);
      $.ajax({
          url:"/wp-content/themes/bluegain2023/_functions/generator.php",    //the page containing php script
          type: "post",    //request type,
          data: {id:id , name:name , content:content},
          success:function(result){
              $('body.single-post main article .bluegain_block--bluegain_pageheader .header-img img').css('height' , '600px');
              $('body.single-post main article .bluegain_block--bluegain_pageheader .container.is-single .row.justify-content-center .main-row').append(sc); 
              $('body.single-post main article .bluegain_block--bluegain_pageheader .container.is-single .main-row.col-lg-10').prepend(cate);
              $('body.single-post main article .bluegain_block--bluegain_pageheader .container.is-single').append(ml);
              $('body.single-post main article .bluegain_block--bluegain_pageheader .header-img').append(ae);
              $('body.single-post main article .bluegain_block--bluegain_teasertext .row.justify-content-center .excerpt-wrapper .btn.btn-bluegain.mt-5.d-none').removeClass("d-none");
              //window.location.href('/wp-content/auto-pdf/'+result+'');
               $('body.single-post main article .bluegain_block--bluegain_teasertext h5').css({'font-size' : '19px','line-height' : '28px','font-weight' : '500','font-style' : 'italic'});
  //            $('body.single-post main article .bluegain_block--bluegain_insightchart .text-center img').css({'max-width':'100%'});
              $( ".col-md-8 .btn-pdf-download" ).attr("href", '/wp-content/auto-pdf/whitepaper_'+id+'-.pdf');
              $(".col-md-8 .btn-pdf-download").removeClass('btn-pdf-download').addClass('download-pdf');
              //setTimeout(function(){
              //  $(".bluegain_block--bluegain_download .col-md-8 .download-pdf").clone().addClass('cloned-btn').appendTo(".bluegain_block--bluegain_download .col-md-8");
              //  var imgC = 'download your pdf <img decoding="async" style="max-width: 60px; margin-left: 50px;" src="/assets/img/btn-arrow.png">';
              //  $(".bluegain_block--bluegain_download .col-md-8 .download-pdf.cloned-btn").html(imgC);
              //  $(".bluegain_block--bluegain_download .col-md-8 .download-pdf").first().remove();
              //  $(".bluegain_block--bluegain_download .col-md-8 .download-pdf.cloned-btn").trigger('click');
              //}, 600);  
              $('.single-post #pdf-gen-loader').hide(); 
              $('.single-post .download-pdf img').show();
              //location.reload();
          }
      });
    } );
    
    
    (function ($) {
      function process_bluegain_sliders(c, e) {
      $('.slider-is-kundenliste').each(function (c, e) {
        let idBlock = $(this).parent().parent().parent().attr('id');
        setTimeout(function(){
          var heights = [];
          $(this).find('.carousel-inner > div').each(function (c, e) {
            height = $(this).outerHeight(true);
            heights.push(height);
          });
          //console.log(heights);
          var newheight = Math.max.apply(Math,heights);
          $(this).find('.carousel-inner > div').css('min-height' , newheight);
        }, 200);
    
    
        
          
    
            // -> kundenliste (Logos & CO)
            // Wrap elms inside wrapper!
    
            if ($(this).hasClass('slider-is-kundenliste')) {
              let items = document.querySelectorAll('#'+idBlock + ' .carousel-item');
              //console.log(idBlock + ' .carousel-item');
              //let items = $(this).find('.carousel-item');
              let  wrapNum = 2;  // default as fallback
    
              if ($(window).width() <= 480) {
                wrapNum = 3;
              }
              if ($(window).width() >= 775) {
                wrapNum = 4;
              }
              if ($(window).width() > 1024) {
                wrapNum = 6;
              }
              if ($(window).width() > 1440) {
                wrapNum = 6;
              }
              //if ($(window).width() > 1680) {
              //  wrapNum = 10;
              //}
              //if ($(window).width() > 1920) {
              //  wrapNum = 12;
              //}
              //if ($(window).width() > 2250) {
              //  wrapNum = 12;
              //}
    
              // OUTDATED:
              // items.forEach((el) => {
              //   const minPerSlide = wrapNum
              //   let next = el.nextElementSibling
              //   for (let i = 1; i < minPerSlide; i++) {
              //     if (!next) {
              //       next = items[0]
              //     }
              //     let cloneChild = next.cloneNode(true)
              //     el.appendChild(cloneChild.children[0])
              //     next = next.nextElementSibling
              //   }
              // });
              //console.log(items);
              var itemcounter = 1;
                items.forEach((el) => {
                  const minPerSlide = wrapNum;
                  let next = items[itemcounter];
                  for (var i = 1; i < minPerSlide; i++) {
                    if(next){
                      let cloneChild = next.cloneNode(true);
                      el.appendChild(cloneChild.children[0]);
                      itemcounter++;
                      next = items[itemcounter];
                    } else {
                      itemcounter++;
                    }
                  }
                  itemcounter++;
                });
    
              $(items).css({ 'transition-duration': (5000 * wrapNum) + 'ms' }); // calculate transition duration
              $(e).find('.inner-item').css({ 'width': (100 / wrapNum) + '%' }); // set width per box inside wrapper row
    
              $(this).find('.carousel-item').each(function(){
                if($(this).children().length <= 1){
                  $(this).css({'border':'1px dotted red'});
                  $(this).remove();
                }
              });
    
              //console.log('bluegain_slider_block \t added counter [' + (c + 1) + '] - kundenliste (patched - with ' + wrapNum + '@' + $(window).width() + ')');
            }
          
        });
      }
        
            //console.log('process_bluegain_sliders \t init');
            process_bluegain_sliders();
            //console.log('process_bluegain_sliders \t done');
        
    
    })(jQuery);
});

$(window).on('resize load', function(){
    if ($(window).width() < 992) {
       $('#bluegain-main-navigation .menu-item .dropdown-menu').removeClass('show');
       //$('#bluegain-main-navigation .current_page_parent > .dropdown-menu').addClass('show');
    }else{
       $('#bluegain-main-navigation .menu-item .dropdown-menu').addClass('show'); 
       $('#bluegain-main-navigation .menu-item .nav-link.dropdown-toggle').css('pointer-events' , 'none'); 
    }
});
